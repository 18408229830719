import React, { useEffect } from "react";
import Loader from "../../Loader/Lodaer";
import request from "../../Requests/requests";
import './SuccessGoogle.css'
import { ReactComponent as SuccessIcon } from "./static/CheckCircle.svg";
import { ReactComponent as FailureIcon } from "./static/alert.svg";
import Button from "../../UIcomponents/Button/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

interface SettingsUserState {
    settings: {
        settings: []
    }
}

export default function SuccessGoogle() {
    const settingsUser = useSelector((state: SettingsUserState)=> state.settings.settings)
    const navigate = useNavigate()
    const receivedUrl = new URL(window.location.href)
    const stylesButton = {
        marginTop: '30px'
    }
    const stylesTitle = {
        fontSize: '15px',
        lineHeight: '20px'
    }
    console.warn(receivedUrl.searchParams.get('state'));
    console.warn(receivedUrl.searchParams.get('code'));
    console.warn(receivedUrl.searchParams.get('error'));

    const redirectFailure = () => {
        navigate('/integrations')
    }

    // const saveChanges = () => {
    //     const newSetting = {...settingsUser, isGCalendarIntegrated: true}
    //     dispatch(changingSettings(newSetting))
    // }

    console.log(settingsUser);
    

    const callbackGoogle = (code?: string | null, error?: string | null, state?: string | null) => {
        console.log(code, error, state);
        request.post('/GoogleCalendar/Callback', {
            userId: localStorage.getItem('token'),
            code: code,
            error: error,
            state: state
        })
    }

    useEffect(() => {
        if (receivedUrl) {
            callbackGoogle(receivedUrl.searchParams.get('code'), receivedUrl.searchParams.get('error'), receivedUrl.searchParams.get('state'))
        }
         /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    if (!receivedUrl) {
        return ( 
            <Loader />
        )
    } else {
        if (receivedUrl.searchParams.get('error') !== null) {
            return (
                <div className="failureGoogle">
                    <div className="failureGoogle__content">
                        <div className="failureGoogle__content__row1">
                            <FailureIcon/>
                            <h1>Упс, что-то пошло не так</h1>
                        </div>
                        <p>Повторите интеграцию с <span className="google-highlight">Google Календарь</span></p>
                        <Button onClickFunc={redirectFailure} stylesButton={stylesButton} stylesTitle={stylesTitle} title={'Попробовать снова'}></Button>
                    </div>
                </div>
                
            )
        } else {
            return (
                <div className="successGoogle">
                    <div className="successGoogle__content">
                        <SuccessIcon/>
                        <h1>Интеграция с <span className="google-highlight">Google Календарь</span> прошла успешно</h1>
                    </div>
                </div>
            )
        }
    }
}