import SubMenu from "../../Submenu/SubmenuApp";
import MainSettings from "./widgets/MainSettingsPage/MainSettingsPage";
import TaskStatsPage from "../TaskStatistics/TaskStatsPage";
import UserDatePage from "./widgets/UserSettingsPage/UserSettingsPage";
import Alert from 'react-bootstrap/Alert';
// import ExitWithoutSaveChanges from "../ModalWindow/ModalWindowSaveChanges";
import ModalWindowCloseWarning from "../../ModalWindow/ModalWindowCloseWarning";
import React, { useEffect, useRef, useState } from "react";
import { modalWindowContext } from "../../Context/modalWindowContext";
import { useLocation, NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { changingSettings } from "../../asyncActions/editSettings";
import { editDataUser } from "../../asyncActions/editDataUser";
import { getAvatar, setAvatar } from "../../asyncActions/avatarService";
import {ReactComponent as ChangeAvatarLogo} from './static/change_avatar_logo.svg';
import Loader from "../../Loader/Lodaer";
import Integrations from "./widgets/Integrations/Integrations";
// import TestPage from "../TestPage";


export default function Settings() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [modalShowCheck, setModalShowCheck] = useState(false);
    const [dataMainSettings, setDataMainSettings] = useState({});
    const [dataUserSettings, setDataUserSettings] = useState({});
    const [isDisabledBtn, setIsDisabledBtn] = useState(true);
    const [getUrl, setGetUrl] = useState();
    const [loading, setLoading] = useState(true);
    const [closeWarningFrom, setCloseWarningFrom] = useState('');
    const [isChanged, setIsChanged] = useState(false);
    const [isSuccess, setIsSuccess] = useState('success-alert-hide');

    let closeAlert;
    let i = 0;

    
    useEffect(() => {
        if(isSuccess === 'success-alert-unhide') {
            // eslint-disable-next-line
            closeAlert = setInterval(autoClose, 1000);
        }
      });
    
    const isDisabled = (payload) => {
        setIsDisabledBtn(payload)
    }

    const getUrlData = (payload) => {
        setGetUrl(payload)
    }

    const fromClose = (payload) => {
        setCloseWarningFrom(payload)
    }

    const isChangedFunction = (payload) => {
        setIsChanged(payload)
    }

    const isSuccesAlert = (payload) => {
        setIsSuccess(payload)
    }

    const updateDataMainSetting = (payload) => {
        setDataMainSettings({
            settingsType: payload.checkedScreen,
            isSmartDateRecognition: payload.checkedSmartDate,
            isTimeTracking: payload.checkedTimeTracking,
            timeZone: payload.timeZone,
            currentTimeZone: payload.currentTZ
        })
    }
    const updateDataUserSetting = (payload) => {
        setDataUserSettings({
            nickname: payload.nickname === null ? '' : payload.nickname.trim(),
            name: payload.name === null ? '' : payload.name.trim(),
            lastname: payload.lastname === null ? '' : payload.lastname.trim(),
            birthday: payload.birthday,
            email: payload.email,
            position: payload.position === null ? '' : payload.position.trim(),
        })
    }
    
    function checkOut() {
        if (isChanged) {
            setModalShowCheck(true);
        }
        else {
            navigate('/', { require: true });
        }
    }

    function autoClose() {
        let timer = i;
        if (timer < 3) {
            timer = timer + 1;
            i = timer;
        } else {
            clearInterval(closeAlert);
            setIsSuccess('success-alert-hide');
        }
    }

    function checkURL() {
        if (closeWarningFrom === 'userDatePage') {
            localStorage.setItem('firstName', dataUserSettings.name);
            localStorage.setItem('lastName', dataUserSettings.lastname);
            localStorage.setItem('position', dataUserSettings.position);
            dispatch(editDataUser(dataUserSettings));
            setIsDisabledBtn(true);
            setIsChanged(false)
            setIsSuccess('success-alert-unhide');
        } else if (closeWarningFrom === 'mainSettingsPage') {
            localStorage.setItem('isTimeTracking', dataMainSettings.isTimeTracking)
            dispatch(changingSettings(dataMainSettings));
            setIsDisabledBtn(true);
            setIsChanged(false)
            setIsSuccess('success-alert-unhide');
        }
    }

    let avaUrl = useSelector(state => state.settings.avatarUrl);

    useEffect(() => {
        dispatch(getAvatar())
            .then(() => {
                setTimeout(() => {
                    setLoading(false)
                }, 200);
            }) 
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [avaUrl])

    const filePickerAva = useRef(null);
    let onClikAva = () => {
        filePickerAva.current.click();
    }

    let onAvaChange = (e) => {
        setLoading(true)
        dispatch(setAvatar(e.target.files[0]));
    }

    const data = {
        warningText: 'Вы внесли изменения, но не сохранили их.',
        userSettingsProp: dataUserSettings,
        mainSettingsProp: dataMainSettings,
        closeWarningFromProp: closeWarningFrom,
        changeFunctionProp: isChangedFunction,
        disabledProp: isDisabled,
        succesProp: isSuccesAlert
    }
    return (
        <>
            <div className="main-container-view">
                <div className="submenu__container">
                    <SubMenu></SubMenu>
                </div>
                <div className="settings-page__container">
                    <div className="settings-page__background">
                        <div className="settings-page__avatar-container">
                            {
                                loading ? 
                                <div>
                                    <Loader></Loader>
                                </div> : null
                            }
                            {
                                loading ? null : 
                                <img className="settings-page__user-avatar"
                                    src={avaUrl}
                                    alt=""
                                />
                            }
                            <div className="avatar" onClick={onClikAva}>
                                <ChangeAvatarLogo className='avatarLogo'></ChangeAvatarLogo>
                                <div className="avatar-text">
                                    изменить фото
                                </div>
                            </div>
                            <input className='hidden'
                                type="file"
                                ref={filePickerAva}
                                onChange={onAvaChange}
                                accept='.png, .jpg, .jpeg' />
                        </div>
                    </div>
                    <div className="settings-page__header">
                        <h1 className="settings-page__title">Настройки</h1>
                        <div className="settings-page__btn-group">
                            <button className="settings-page__btn-close" onClick={() => checkOut()}>Закрыть</button>
                            <button className="settings-page__btn-save" style={location.pathname === getUrl ? {display: 'none'} : null} disabled={isDisabledBtn} onClick={() => checkURL(closeWarningFrom)}>Сохранить</button>
                        </div>
                    </div>
                    <div className="settings-page__nav-menu">
                        <ul className="settings-page__nav-list">
                            <li className="settings-page__nav-item">
                                <NavLink to="/profile" className={({ isActive }) => isActive ? 'nav-link-active' : 'nav-link'}>Профиль</NavLink>
                            </li>
                            <li className="settings-page__nav-item" >
                                <NavLink to="/settings" className={({ isActive }) => isActive ? 'nav-link-active' : 'nav-link'}>Основное</NavLink>
                            </li>
                            <li className="settings-page__nav-item">
                                <NavLink to="/statistics" className={({ isActive }) => isActive ? 'nav-link-active' : 'nav-link'}>Статистика</NavLink>
                            </li>
                            <li className="settings-page__nav-item">
                                <NavLink to="/integrations" className={({ isActive }) => isActive ? 'nav-link-active' : 'nav-link'}>Интеграции</NavLink>
                            </li>
                            {/* <li className="settings-page__nav-item">
                                <NavLink to="/settings" className={({isActive}) => isActive ? 'nav-link-active' : 'nav-link'}>Подписка</NavLink>
                            </li>
                            <li className="settings-page__nav-item">
                                <NavLink to="/settings" className={({isActive}) => isActive ? 'nav-link-active' : 'nav-link'}>Напоминания</NavLink>
                            </li> */}
                            {/* <li className="settings-page__nav-item">
                                <NavLink to="/#" className={({ isActive }) => isActive ? 'nav-link-active' : 'nav-link'}>Уведомления</NavLink>
                            </li> */}
                            {/* <li className="settings-page__nav-item">
                                <NavLink to="/test" className={({isActive}) => isActive ? 'nav-link-active' : 'nav-link'}>тест</NavLink>
                            </li> */}
                        </ul>
                    </div>
                    <div className={isSuccess}>
                        <Alert variant='primary'>
                            Данные изменены!
                        </Alert>
                    </div>
                    {location.pathname === '/profile' ? <UserDatePage isChangedFunction={isChangedFunction} 
                                                                    isDisabled={isDisabled} 
                                                                    updateDataUserSetting={updateDataUserSetting}
                                                                    isSuccesAlert={isSuccesAlert}
                                                                    fromClose={fromClose}/> : null}
                    {location.pathname === '/settings' ? <MainSettings isChangedFunction={isChangedFunction} 
                                                                    isDisabled={isDisabled} 
                                                                    updateDataMainSetting={updateDataMainSetting}
                                                                    fromClose={fromClose}/> : null}
                    {location.pathname === '/statistics' ? <TaskStatsPage getUrlData={getUrlData} /> : null}
                    {location.pathname === `/statistics/${params.id}/modal=true` ? <TaskStatsPage getUrlData={getUrlData} /> : null}
                    {location.pathname === '/integrations' ? <Integrations/> : null}
                    {/* {location.pathname === '/test' ? <TestPage getUrlData={getUrlData} /> : null} */}
                </div>
            </div>
            {modalShowCheck ? <modalWindowContext.Provider value={[modalShowCheck, setModalShowCheck]}>
                <ModalWindowCloseWarning  data={data}/>
            </modalWindowContext.Provider>
                : null
            }

        </>
    )
}